import React from "react"
// import { Link } from "gatsby"
import {Helmet} from "react-helmet"

import SiniestrosLayout from "../../components/siniestros-layout"

import icoExperta from "../../assets/old/img/logos-ase/ico-experta.png"

export default () => (
  <SiniestrosLayout subCompaniaActivo="experta">
    <Helmet>
      <title>Experta Seguros - Siniestros</title>
      <meta
        name="description"
        content="Servicio de asistencia: 0800-7777-278."
      />
    </Helmet>

    <hr className="mb-4"/>
    {/* Quotation */}
    <div className="card blog-card mb-2">
      <div className="card-body px-sm-5 py-5 bg-secondary">
        <img
          className="float-md-right pb-2"
          src={icoExperta}
          alt="Experta Seguros"
        />
        <h4 className="lead post-title text-muted">Experta Seguros</h4>
        <p className="">Servicio de asistencia: Serivicio de asistencia: 0800-7777-278.</p>
      </div>
    </div>
    <p className="pt-5 pb-3">
      Ante un siniestro realizá la denuncia correspondiente descargando y enviando el formulario y los documentos
      respectivos, en los casos que sean solicitados, a: clientes@experta.com.ar
    </p>
    <p>
      Conocer formularios para sus prodctos en:
      <a href="https://www.experta.com.ar/denuncia-siniestros/"
         target="_blank"
         rel="noopener noreferrer">
        <strong>
          <span className="text-primary">www.experta.com.ar/denuncia-siniestros/</span>
        </strong>
      </a>
    </p>

    <hr className="pb-4"/>


  </SiniestrosLayout>
)
